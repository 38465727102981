import { defineStore } from "pinia";
import { ProductDetailsForBackOffice } from "./api/scion/models/ProductDetailsForBackOffice";

export const useGlobalStore = defineStore("globalStore", {
  state: () => ({
    customerId: null as string | null,
    customerName: null as string | null,
    brandApprovalAccessToken: null, // From two ui apps
    token: null as string | null,
  }),
});

interface ProductTypes {
  product_code: string;
  settings_group_name: string;
  country_code: string;
}

export interface ProductListTypes {
  products: ProductTypes[];
  country_codes: string[];
}

export const useT3Store = defineStore("t3-form", {
  state: () => ({
    productList: {} as ProductListTypes,
    selectedCountryCodes: [],
    selectedProducts: [],
    availableProductsForCustomer: [] as ProductDetailsForBackOffice[],
  }),
  actions: {
    setProductList(productList: ProductListTypes) {
      this.productList = productList;
    },
    setSelectedCountryCodes(countryCodes: any) {
      this.selectedCountryCodes = countryCodes;
    },
    setSelectedProducts(products: any) {
      this.selectedProducts = products;
    },
    setAvailableProductsForCustomer(products: ProductDetailsForBackOffice[]) {
      this.availableProductsForCustomer = products;
    },
  },
});
